import {useContext} from "react"
import {ConfigContext} from "src/app"
import {FormControl} from "src/components/shared/inputs/form-control"
import {FormControlPassword} from "src/components/shared/inputs/form-control-password"
import {useTranslation} from "react-i18next"
import {FormControlMask} from "src/components/shared/inputs/form-control-mask"
import {FormProvider, useForm} from "react-hook-form"
import driverService from "src/services/driver.service"
import {SharedInventorySelect} from "src/components/shared/components/select/inventory"
import modalService from "src/components/modal/global/modal.service"
import {BehaviorSubject} from "rxjs"

const DriverForm = ({onCreate}: {onCreate: BehaviorSubject<void>}) => {
  const {settings} = useContext(ConfigContext)
  const form = useForm({defaultValues: {inventories: []}})
  const {handleSubmit} = form
  const {t} = useTranslation()

  const onSubmit = async (payload: any) => {
    await driverService.postDriver(payload)
    onCreate.next()
    modalService.closeModal()
  }

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-6">
        <FormControl
          name="first_name"
          type="text"
          label={t("transfers.form.first_name")}
          rootclassname="flex-1"
          className="form-control"
        />
        <FormControl
          name="last_name"
          type="text"
          label={t("transfers.form.last_name")}
          rootclassname="flex-1"
          className="form-control"
        />
        <FormControlMask
          label={t("clients.form.phone")}
          placeholder={t("common.input.placeholder.phone_number")}
          rootclassname="col"
          name="phone"
          mask={settings && settings.phone_format}
          className="form-control"
          required
          params={{required: true}}
        />
        <SharedInventorySelect
          name="inventories"
          className="w-full"
          label={t("transfers.main.driver")}
          placeholder={t("transfers.form.choose_cars")}
          params={{required: true}}
          isSearchable={false}
          isClearable={true}
          isMulti
        />
        <FormControl
          label={t("users.form.email")}
          placeholder={t("users.form.placeholder.email")}
          rootclassname="col"
          className="form-control"
          name="email"
          params={{
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t("users.form.error.email")
            }
          }}
        />
        <FormControlPassword
          label={t("users.password_form.password")}
          placeholder={t("users.password_form.password")}
          rootclassname="col mb-3"
          params={{required: true, minLength: 8}}
          className="form-control"
          name="password"
        />
        <button
          onClick={handleSubmit(onSubmit)}
          className="w-full btn btn-primary btn-color-white text-base font-medium leading-5 py-4 mt-4">
          Создать и добавить
        </button>
      </div>
    </FormProvider>
  )
}

export default DriverForm
