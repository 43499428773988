import React, {useContext, useEffect} from "react"
import {Navigate, useLocation, useNavigate} from "react-router-dom"
import {ConfigContext} from "src/app"
import {TenantTypeConfigs} from "../../mock/functionality-access"
import hasAccess from "../../utils/hasAccess"
import {GroupType} from "../../services/auth.service"
import {TenantType} from "src/services/tenant.service"

interface ProtectedRouteProps {
  element: React.ReactElement
  requiredAccessKey: keyof TenantTypeConfigs["accesses"] | string
  redirectRoute?: string
}

const restrictedForDriver = ["/transportation", "/drivers", "/cities", "/reports"]
const adminRestrictions = [
  "/transportation",
  "/transportation/all",
  "/transportation/request",
  "/transportation/inprocess",
  "/transportation/completed"
]

export function ProtectedRoute({element, requiredAccessKey, redirectRoute}: ProtectedRouteProps) {
  const {
    profile,
    tenantConfigs: {mainRoute, type, accesses}
  } = useContext(ConfigContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!profile) return

    if (type === TenantType.TRANSFER) {
      const pathname = location.pathname
      const isRestrictedPathDriver = restrictedForDriver.some((path) => pathname.startsWith(path))

      if (pathname.startsWith("/orders")) navigate("/transportation")

      if (!profile.is_superuser) {
        if (profile.group_type === GroupType.DRIVER && isRestrictedPathDriver) navigate("/rides")
        if (profile.group_type === GroupType.ADMIN && adminRestrictions.includes(pathname)) navigate("/rides")
        if (profile.group_type === GroupType.MANAGER && pathname.startsWith("/rides")) navigate("/transportation")
      }
    }
  }, [profile, type, location.pathname])

  if (!hasAccess(accesses, requiredAccessKey)) {
    return <Navigate to={redirectRoute ?? mainRoute} />
  }

  return element
}
