import {useTranslation} from "react-i18next"
import Icon from "src/components/shared/components/material-icon"
import {useNavigate} from "react-router-dom"

import {useParams} from "react-router"
import {TransferDetail} from "src/models/manager/transfer/transfer"
import {Empty} from "antd"
import TransportationCard from "./transportation-card"

export interface AddTransferProps {
  transfer: TransferDetail
  deleteRide: (id: number, rideId: number) => void
  isAdmin: boolean
}

const AddTransfer = ({transfer, deleteRide, isAdmin}: AddTransferProps) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {id} = useParams()
  const createNewOrder = () => navigate(`/transportation/${id}/ride`)

  return (
    <div className="card-wrapper flex flex-col gap-3 rounded-none md:rounded-lg shadow-none md:shadow flex-1 p-4 md:p-5 border-none md:border-gray-100">
      <div className="text-xl font-bold">{t("transfers.main.transfer")}</div>
      <div className={`${transfer.rides.length > 0 && "grid-cols-1 gap-4 md:grid-cols-2"} w-full grid`}>
        {transfer.rides.length > 0 ? (
          transfer.rides.map((ride) => <TransportationCard key={ride.id} ride={ride} deleteRide={deleteRide} />)
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{margin: "8px auto"}} />
        )}
      </div>

      {!isAdmin && (
        <button className="btn btn-card gap-2 w-full" onClick={createNewOrder}>
          <span>{t("common.add")}</span>
          <Icon icon="add" />
        </button>
      )}
    </div>
  )
}

export default AddTransfer
