import axios from "axios"
import {ApiAbstract} from "src/abstract/api.abstract"
import {ListModel} from "src/models/common"
import {DriverExpense, Drivers, UserDriver} from "../models/manager/transfer/drivers"

export class DriverService extends ApiAbstract {
  constructor() {
    super("v1/crm/drivers")
  }

  public async listDriver(params?: any): Promise<ListModel<Drivers>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }
  public async getDriver(id?: number): Promise<any> {
    const response = await axios.get(this.getUrl(`${id}`))
    return response.data
  }
  public async postDriver(payload?: any): Promise<UserDriver> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async getExpensesList(params?: any): Promise<any> {
    const response = await axios.get(this.getUrl(`expenses`), {params})
    return response.data
  }
  public async getExpenseDetail(driverId: number, id: number): Promise<DriverExpense> {
    const response = await axios.get(this.getUrl(`${driverId}/expenses/${id}`))
    return response.data
  }
  public async getExpenseID(id: number): Promise<DriverExpense> {
    const response = await axios.get(this.getUrl(`expenses/${id}`))
    return response.data
  }
  public async getDriverExpenses(id?: number, params?: any): Promise<ListModel<DriverExpense>> {
    const response = await axios.get(this.getUrl(`${id}/expenses`), {params})
    return response.data
  }
  public async postExpense(payload?: any): Promise<any> {
    const response = await axios.post(this.getUrl(`expenses`), payload)
    return response.data
  }
  public async deleteExpense(id?: number): Promise<any> {
    const response = await axios.delete(this.getUrl(`expenses/${id}`))
    return response.data
  }
}

const driverService = new DriverService()
export default driverService
