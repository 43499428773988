import {useTranslation} from "react-i18next"

export const getOrderStatus = (status: string) => {
  const {t} = useTranslation()
  const colors = {
    primary: "var(--color-primary)",
    warn: "var(--color-warn)",
    green: "var(--color-green-500)",
    undefined: "var(--color-gray-350)"
  }
  switch (status) {
    case "request":
      return {
        text: t("transfers.statuses.request"),
        bg: "#1890FF"
      }
    // case "in_ride":
    //   return {
    //     text: t("transfers.statuses.in_ride"),
    //     bg: colors.warn
    //   }
    case "inprocess":
      return {
        text: t("transfers.statuses.in_work"),
        bg: colors.green
      }
    case "completed":
      return {
        text: t("transfers.statuses.finished"),
        bg: colors.primary
      }
    default:
      return {
        text: "Undefined",
        bg: colors.undefined
      }
  }
}
