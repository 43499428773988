import {useContext, useEffect, useState} from "react"
import HeaderContent from "src/components/header/header-content"
import TransferStepper from "./transfer-stepper"
import {FormControl} from "src/components/shared/inputs/form-control"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import TransferCity from "src/components/shared/components/select/transfer-city"
import TransferDispatchPoint from "src/components/shared/components/select/transfer-dispatchPoint"
import transportationService from "src/services/transportation.service"
import {useNavigate} from "react-router-dom"
import {ConfigContext} from "src/app"
import {useParams} from "react-router"
import {FormControlTextarea} from "src/components/shared/inputs/form-control-textarea"
import {TransferAddress, TransferRideModel} from "src/models/manager/transfer/transfer"
import {FormControlDatetime} from "src/components/shared/inputs/form-control-datetime"
import {GroupType} from "src/services/auth.service"
import DriverSelection from "../../../details/select/driver-selection"
import TransferHeader from "../../../details/ui/headers/transfer-header"

const TransferForm = () => {
  const form = useForm()
  const {t} = useTranslation()
  const {id, rideId} = useParams()
  const {formState, watch, reset, handleSubmit} = form
  const {settings, profile} = useContext(ConfigContext)
  const navigate = useNavigate()
  const isAdmin = profile && profile.group_type === GroupType.ADMIN
  const [ride, setRide] = useState(null)
  const values = watch()
  const startCityId = values.start_city
  const endCityId = values.end_city
  const startAddress = values.start_address
  const endAddress = values.end_address

  const {hideMobileMenu, showMobileMenu, isMobile} = useContext(ConfigContext)
  const [startAddressDetails, setStartAddressDetails] = useState<TransferAddress>(undefined)
  const [endAddressDetails, setEndAddressDetails] = useState<TransferAddress>(undefined)

  const onSubmit = handleSubmit(async (payload: any) => {
    if (rideId) await transportationService.putRides(+id, +rideId, payload)
    if (!rideId) await transportationService.postRides(+id, payload)

    navigate(-1)
  })

  const rideCheck = ride && ride?.fact_start_at

  useEffect(() => {
    if (rideId) {
      transportationService.getRide(+id, +rideId).then((data) => {
        setRide(data)
        reset(data)
      })
    }
  }, [rideId, id, reset])

  useEffect(() => {
    hideMobileMenu()
    return () => showMobileMenu()
  }, [hideMobileMenu, showMobileMenu])

  useEffect(() => {
    setStartAddressDetails(null)
    if (startAddress) {
      transportationService.getAddress(+startAddress).then((res) => {
        setStartAddressDetails(res)
      })
    } else {
      setStartAddressDetails(null)
    }
  }, [startAddress])

  useEffect(() => {
    setEndAddressDetails(null)
    if (endAddress) {
      transportationService.getAddress(+endAddress).then((res) => {
        setEndAddressDetails(res)
      })
    } else {
      setEndAddressDetails(null)
    }
  }, [endAddress])

  const renderAddressDetails = (details: TransferAddress, field: keyof TransferRideModel) => {
    const {extra} = details || {extra: {}}
    return (
      <div className="flex gap-3 w-100">
        {Object.keys(extra).map((key) => (
          <FormControl
            key={key}
            name={`${field}.${key}`}
            rootclassname="flex-1"
            className="form-control"
            label={extra[key]}
            placeholder={extra[key]}
            disabled={isAdmin}
          />
        ))}
      </div>
    )
  }

  return (
    <FormProvider {...form}>
      <HeaderContent>
        <TransferHeader onSubmit={onSubmit} disabled={!formState.isDirty} />
      </HeaderContent>

      <div className="-mx-4 -mt-4 md:mx-0 md:mt-0 flex flex-col gap-0.5">
        {profile && (profile.group_type === GroupType.ADMIN || profile.is_superuser) && (
          <div className="w-full max-w-[768px] m-auto  mb-0 md:mb-3">
            <DriverSelection className="mb-0 md:mb-3" disabled={!!rideCheck} />
            <TransferStepper className="rounded-none  mb-0 md:mb-3 md:rounded-lg border-none md:border-gray-100" />
          </div>
        )}
        <div className="w-full max-w-[768px] m-auto bg-white rounded-none md:rounded-lg md:shadow flex flex-col gap-5 p-4">
          <div className="flex flex-col gap-3 flex-1">
            <h3 className="text-xl font-bold">{t("transfers.form.general_information")}</h3>
            <div className="flex gap-3">
              <FormControl
                name="client_count"
                type="number"
                label={t("transfers.form.number_of_people")}
                rootclassname="flex-1"
                className="form-control form-control-number"
                number_only={+true}
                number_decimal={+false}
                placeholder={t("transfers.form.number_of_people")}
                required
                disabled={isAdmin}
                params={{required: t("transfers.form.enter_count_of_people")}}
              />
              <FormControl
                name="price"
                type="number"
                label={t("transfers.form.transfer_price")}
                rootclassname="flex-1"
                className="form-control form-control-number"
                number_only={+true}
                number_decimal={+false}
                suffix={settings && settings.currency}
                placeholder={t("transfers.form.enter_price")}
                required
                disabled={isAdmin}
                params={{required: t("transfers.form.enter_price")}}
              />
            </div>
          </div>

          <div className="flex flex-col gap-3 flex-1">
            <div className="text-xl font-bold">{t("transfers.form.transfer_details")}</div>

            <FormControlDatetime
              name="start_at"
              rootclassname="w-full"
              className="form-control"
              label={t("transfers.form.departure_date_and_time")}
              params={{required: true}}
              disabled={isAdmin}
              datetimeConfig={{
                minuteStep: 1,
                showTime: {format: "HH:mm"},
                allowClear: false
              }}
            />

            <div className="flex flex-col gap-3 md:flex-row">
              <TransferCity
                className="col"
                label={t("transfers.form.from_city")}
                placeholder={t("transfers.form.from_city")}
                name="start_city"
                isClearable={true}
                isSearchable={false}
                addOption
                required
                disabled={isAdmin}
                params={{required: t("transfers.form.enter_departure_city")}}
              />
              {startCityId && (
                <TransferDispatchPoint
                  key={startCityId}
                  className="col"
                  label={t("transfers.form.from_address")}
                  placeholder={t("transfers.form.from_address")}
                  name="start_address"
                  isClearable={true}
                  isSearchable={false}
                  addOption
                  required
                  disabled={isAdmin}
                  params={{required: t("transfers.form.enter_departure_address")}}
                  cityId={startCityId}
                />
              )}
            </div>
            {renderAddressDetails(startAddressDetails, "start_extra")}

            <div className="border-b border-gray-100" />

            <div className="flex flex-col gap-3 md:flex-row">
              <TransferCity
                className="col"
                label={t("transfers.form.to_city")}
                placeholder={t("transfers.form.to_city")}
                name="end_city"
                isClearable={true}
                isSearchable={false}
                addOption
                required
                disabled={isAdmin}
                params={{required: t("transfers.form.enter_arrival_city")}}
              />
              {endCityId && (
                <TransferDispatchPoint
                  key={endCityId}
                  className="col"
                  label={t("transfers.form.to_address")}
                  placeholder={t("transfers.form.to_address")}
                  name="end_address"
                  isClearable={true}
                  isSearchable={false}
                  addOption
                  required
                  disabled={isAdmin}
                  params={{required: t("transfers.form.enter_arrival_address")}}
                  cityId={endCityId}
                />
              )}
            </div>
            {renderAddressDetails(endAddressDetails, "end_extra")}
          </div>

          <div className="flex flex-col gap-3 flex-1">
            <div className="text-xl font-bold">{t("transfers.form.additionally")}</div>
            <FormControlTextarea
              name="comment"
              rootclassname="col"
              className="form-control"
              label={t("transfers.form.comment_optional")}
              placeholder={t("transfers.form.enter_a_comment")}
              disabled={isAdmin}
            />
          </div>
        </div>
      </div>

      {isMobile && (
        <div className="fixed left-0 bottom-0 right-0 flex items-center w-full shadow-menu bg-white min-h-[72px] z-[100]">
          <div className="flex gap-1 flex-col w-full px-4 py-2 md:px-0 md:py-0">
            <button
              className="btn btn-primary btn-color-white text-base font-medium leading-5 py-4"
              onClick={onSubmit}
              disabled={!formState.isDirty}>
              {t("common.save")}
            </button>
          </div>
        </div>
      )}
    </FormProvider>
  )
}

export default TransferForm
