import {FormControl} from "src/components/shared/inputs/form-control"
import Icon from "src/components/shared/components/material-icon"
import TrasfersRideCard from "../../pages/transfer/detail/trasfers-ride-card"
import {FormProvider, useForm} from "react-hook-form"
import React, {useContext, useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import modalService from "src/components/modal/global/modal.service"
import {createPortal} from "react-dom"
import {useNavigate} from "react-router-dom"
import {EMPTY_LIST, ListModel} from "src/models/common"
import transportationService from "src/services/transportation.service"
import {Transfer} from "src/models/manager/transfer/transfer"
import {useDebounce} from "src/hooks/useDebounce"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import {isNil} from "src/utils/isNil"
import useOnScreen from "src/hooks/useOnScreen"
import TransferMainFilterModal from "../../details/modal/transfer-main-filter"
import {FormControlDaterange} from "src/components/shared/inputs/form-control-daterange"

export default function TransportationFilter(props: {request: string}) {
  const {request} = props
  const currentRoute = useCurrentRoute()
  const form = useForm({
    defaultValues: {
      search: "",
      page: 1,
      pageSize: 10,
      status: request,

      start_at__gt: "",
      start_at__lte: ""
    }
  })
  const elementRef = useRef<HTMLDivElement>()
  const isOnScreen = useOnScreen(elementRef)

  const {reset, watch} = form
  const {t} = useTranslation()
  const values = watch()
  const {isMobile} = useContext(ConfigContext)
  const navigate = useNavigate()

  const [list, setList] = useState<ListModel<Transfer>>(EMPTY_LIST)
  const searchDebounce = useDebounce(watch("search"), 1000)

  const onLoad = async (params: any): Promise<void> => {
    const response = await transportationService.list(params)
    setList((prev) => ({
      ...response,
      results: params?.page === 1 ? response.results : [...prev.results, ...response.results]
    }))
  }
  console.log(watch("start_at__gt"))

  const createTransfer = async () => {
    const response = await transportationService.post({})
    if (response?.id) {
      navigate(`/transportation/${response.id}`)
    }
  }

  useEffect(() => {
    reset({...values, page: 1, search: searchDebounce})
  }, [searchDebounce])

  useEffect(() => {
    reset({...values, page: 1, status: isNil(request) ? null : request})
  }, [currentRoute])

  useEffect(() => {
    onLoad({...values, status: isNil(request) ? null : request})
    const sub = watch((params, {name}) => {
      if (name !== "search") onLoad(params)
    })
    return () => {
      sub.unsubscribe()
      setList(EMPTY_LIST)
    }
  }, [])

  useEffect(() => {
    if (isOnScreen && list.next !== false && !!list.next) {
      reset({
        ...values,
        page: values.page + 1
      })
    }
  }, [isOnScreen])

  const onShowModal = () => {
    modalService.open({
      props: {size: "lg"},
      component: <TransferMainFilterModal form={form} />
    })
  }

  console.log()
  return (
    <FormProvider {...form}>
      <div className="-mx-4 -mt-4 md:mx-0 md:mt-0">
        {isMobile ? (
          <div className="flex gap-2 justify-between mb-3 p-2 border-b border-gray-100 shadow bg-white">
            <FormControl
              icon="search"
              rootclassname="w-full"
              className="form-control"
              name="search"
              placeholder={t("common.input.search")}
            />
            <div
              className="min-h-12 min-w-12 rounded-lg border border-gray-200 flex justify-center items-center"
              onClick={onShowModal}>
              <Icon icon="filter_list" className="h-5 w-6 text-2xl" />
            </div>
          </div>
        ) : (
          <div className="flex gap-2 mb-3 ">
            <FormControl
              icon="search"
              rootclassname="col-4"
              className="form-control"
              name="search"
              placeholder={t("common.input.search")}
            />
            <FormControlDaterange className="col form-control" name_start="start_at__gt" name_end="start_at__lte" />
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {list.results.map((transfer, index) => (
          <TrasfersRideCard key={index} transfer={transfer} />
        ))}
        <div ref={elementRef}></div>
      </div>
      {isMobile &&
        createPortal(
          <button
            className="fixed z-[100]  bottom-20 md:bottom-10 right-4 h-14 w-14 rounded-full bg-primary border border-white border-opacity-20 shadow-[0_0_16px_0_rgba(0, 0, 0, 0.16)]"
            style={{padding: 10, overflow: "hidden", width: "100%"}}
            onClick={() => createTransfer()}>
            <Icon icon="add" className={"text-white text-2xl"} />
          </button>,
          document.body
        )}
    </FormProvider>
  )
}
