import React, {useContext} from "react"
import {DriverContext} from "./driver-context"
import {useTranslation} from "react-i18next"

const DriverInfoComponent = () => {
  const {driver} = useContext(DriverContext)
  const {t} = useTranslation()
  return (
    <div>
      <h4 className="mb-3 text-xl font-bold"> Basic information</h4>
      <div className="flex flex-col gap-4">
        <div className="flex flex-column gap-2">
          <span className="text-gray-400 text-[13px]">{t("clients.form.email")}:</span>
          <span>{driver.emai && driver.email}</span>
        </div>
        <div className="flex flex-column gap-2">
          <span className="text-gray-400 text-[13px]">{t("clients.form.phone")}:</span>
          <span>{driver.phone && driver.phone}</span>
        </div>
        <div className="flex flex-column gap-2">
          <span className="text-gray-400 text-[13px]">
            {driver.inventories.length > 0 && driver.inventories.length === 1
              ? t("transfers.main.car")
              : t("transfers.form.cars")}
            :
          </span>
          {driver.inventories && driver.inventories.map((inv) => <span>{inv.name}</span>)}
        </div>
      </div>
    </div>
  )
}

export default DriverInfoComponent
