import {FormProvider, useForm} from "react-hook-form"
import React, {useContext, useEffect, useRef, useState} from "react"
import {EMPTY_LIST, ListModel} from "src/models/common"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"
import {isNil} from "src/utils/isNil"
import useOnScreen from "src/hooks/useOnScreen"
import ridesService from "src/services/rides.service"
import RideCard from "./detail/ride-card"
import {TransferRideModel} from "src/models/manager/transfer/transfer"
import {FormControl} from "../../../../components/shared/inputs/form-control"
import Icon from "../../../../components/shared/components/material-icon"
import {FormControlDaterange} from "../../../../components/shared/inputs/form-control-daterange"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "../../../../app"
import OrderListAmountComponent from "../../../orders/list/order-amount"
import ordersService from "../../../../services/order-requests/order.service"
import OrderListTableTabComponent from "../../../orders/list/order-tab.table"

export default function RidesFilter(props: {request: string}) {
  const {request} = props
  const currentRoute = useCurrentRoute()
  const form = useForm({
    defaultValues: {
      page: 1,
      pageSize: 10,
      status: request
    }
  })
  const elementRef = useRef<HTMLDivElement>()
  const isOnScreen = useOnScreen(elementRef)
  const {isMobile} = useContext(ConfigContext)
  const {t} = useTranslation()
  const {reset, watch} = form
  const values = watch()
  const [list, setList] = useState<ListModel<TransferRideModel>>(EMPTY_LIST)
  const [type, setType] = useState<"table" | "grid">((localStorage.getItem("order-view") as "table" | "grid") || "grid")

  // const onLoad = async (params: any): Promise<void> => {
  //   ridesService.list(params).then((res) => {
  //     if (type === "table") setList(res)
  //     if (type === "grid")
  //       setList((prevList) => ((params.page === 1 ? res : {...res, results: [...prevList.results, ...res.results]})
  //   })

  const onLoad = async (params: any): Promise<void> =>
    ridesService.list({...params}).then((res) => {
      if (type === "table") setList(res)
      if (type === "grid")
        setList((prevList) => (params.page === 1 ? res : {...res, results: [...prevList.results, ...res.results]}))
    })

  const toggleTable = () =>
    setType(() => {
      localStorage.setItem("order-view", "table")
      return "table"
    })

  const toggleGrid = () =>
    setType(() => {
      localStorage.setItem("order-view", "grid")
      return "grid"
    })

  useEffect(() => {
    onLoad({...values, status: isNil(request) ? null : request})
    return () => setList(EMPTY_LIST)
  }, [currentRoute])

  useEffect(() => {
    if (isOnScreen && list.next !== false && !!list.next) {
      reset({...values, page: values.page + 1})
    }
  }, [isOnScreen])

  useEffect(() => {
    reset({...values, page: 1})
  }, [])

  return (
    <FormProvider {...form}>
      <div className="-mx-4 -mt-4 md:mx-0 md:mt-0">
        {isMobile ? (
          <div className="flex gap-2 justify-between mb-3 p-2 border-b border-gray-100 shadow bg-white">
            <FormControl
              icon="search"
              rootclassname="w-full"
              className="form-control"
              name="search"
              placeholder={t("common.input.search")}
            />
            <div
              className="min-h-12 min-w-12 rounded-lg border border-gray-200 flex justify-center items-center"
              onClick={() => {}}>
              <Icon icon="filter_list" className="h-5 w-6 text-2xl" />
            </div>
          </div>
        ) : (
          <div className="flex gap-2 mb-3 ">
            <FormControl
              icon="search"
              rootclassname="col-4"
              className="form-control"
              name="search"
              placeholder={t("common.input.search")}
            />
            <FormControlDaterange className="col form-control" name_start="start_at__gt" name_end="start_at__lte" />
          </div>
        )}
      </div>
      <div className="flex gap-2 items-stretch">
        {type === "grid" && (
          <span className="color-black font-medium align-self-center mx-2">
            {t("orders.list.total")}: {list.count}
          </span>
        )}

        <div
          className={`btn btn-card font-medium ${type === "table" ? "btn-primary btn-color-white" : ""}`}
          onClick={toggleTable}>
          <Icon className="text-[21px]" icon="menu" />
        </div>
        <div
          className={`btn btn-card font-medium ${type === "grid" ? "btn-primary btn-color-white" : ""}`}
          onClick={toggleGrid}>
          <Icon className="text-[21px]" icon="dataset" />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        {isMobile || (type === "grid" && list.results.map((ride) => <RideCard key={ride.id} ride={ride} />))}
        {type === "table" && !isMobile && <></>}
        <div ref={elementRef}></div>
      </div>
    </FormProvider>
  )
}
