import {useContext, useEffect, useMemo, useRef, useState} from "react"
import PointStepper from "../../../details/ui/point-stepper"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import {ConfigContext} from "src/app"
import {useNavigate} from "react-router-dom"
import {useParams} from "react-router"
import clsx from "clsx"
import {useContainerDimensions} from "src/hooks/useContainerDimensions"
import ridesService from "src/services/rides.service"
import {TransferRideModel} from "src/models/manager/transfer/transfer"
import {useTranslation} from "react-i18next"
import {isNil} from "src/utils/isNil"

type Step = "start" | "finish" | "completed"

const RideStepper = () => {
  const navigate = useNavigate()
  const {rideId} = useParams()
  const ref = useRef(null)
  const btnRef = useRef(null)
  const startX = useRef(null)
  const [ride, setRide] = useState<TransferRideModel>(null)
  const [loading, setLoading] = useState(false)
  const [dragOffset, setDragOffset] = useState(0)
  const {t} = useTranslation()
  const {width: wrapperWidth} = useContainerDimensions(ref, ride)
  const {width: btnWidth} = useContainerDimensions(btnRef, ride)
  const {hideMobileMenu, showMobileMenu, isMobile} = useContext(ConfigContext)

  const step: Step | null = useMemo(() => {
    if (!ride) return null
    if (isNil(ride.fact_start_at) && isNil(ride.fact_end_at)) return "start"
    if (!isNil(ride.fact_start_at) && isNil(ride.fact_end_at)) return "finish"
    return "completed"
  }, [ride])

  useEffect(() => {
    hideMobileMenu()
    return () => showMobileMenu()
  }, [hideMobileMenu, showMobileMenu])

  const handleTouchStart = (e) => {
    e.preventDefault()
    if (loading) return
    startX.current = e.touches[0].clientX
  }

  const handleTouchMove = (e) => {
    e.preventDefault()
    if (loading) return
    const currentX = e.touches[0].clientX
    const offset = currentX - startX.current
    const _dragOffset = Math.min(Math.max(0, offset), wrapperWidth - 16 - btnWidth)
    btnRef.current.style.width = _dragOffset + btnWidth
    setDragOffset(_dragOffset)
  }

  const handleTouchEnd = (e) => {
    e.preventDefault()
    if (wrapperWidth - 16 <= dragOffset + btnWidth) {
      setDragOffset(wrapperWidth - 16 - btnWidth)
      setLoading(true)
      onSubmit()
    } else {
      setDragOffset(0)
    }
  }

  const getRide = async (id: number) => {
    return ridesService.get(id).then(setRide)
  }

  useEffect(() => {
    getRide(+rideId)
  }, [rideId])

  const onSubmit = async () => {
    switch (step) {
      case "start":
        return ridesService.rideStart(+rideId).then(() => {
          setDragOffset(0)
          setLoading(false)
          getRide(+rideId)
        })
      case "finish":
        return ridesService.rideFinish(+rideId).then(() => {
          setDragOffset(0)
          setLoading(false)
          getRide(+rideId)
          navigate("finish")
        })
      case "completed":
        return Promise.reject()
    }
  }

  return (
    ride && (
      <>
        <HeaderContent>
          <div className="flex header-content p-0">
            <div className="flex items-center gap-2 w-full px-4 py-3">
              {isMobile && <Icon icon="chevron_left" className="text-2xl" onClick={() => navigate(-1)} />}
              <div className="text-lg font-semibold md:text-2xl">
                {t("transfers.main.transfer")} № {rideId}
              </div>
            </div>
          </div>
        </HeaderContent>

        <PointStepper ride={ride} />

        {ride && ["start", "finish"].includes(step) && (
          <div
            ref={ref}
            className="fixed flex items-center w-[calc(100%_-_16px)] left-2 right-2 px-2 shadow-menu max-h-[72px] min-h-[72px] bottom-3 z-[100] rounded-full bg-gray-100 p-2">
            {loading ? (
              <div className="flex gap-3 items-center justify-center p-4 h-[56px] rounded-full bg-white opacity-50 font-medium text-base w-full">
                <Icon icon="circle" />
                <span>{t("transfers.form.processing")}</span>
              </div>
            ) : (
              <div
                ref={btnRef}
                className={clsx("flex gap-3 items-center justify-end p-4 h-[56px] rounded-full font-medium text-base", {
                  "bg-black text-white": ride.fact_start_at,
                  "bg-primary text-white": !ride.fact_start_at
                })}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}>
                <div style={{width: dragOffset}}></div>
                <span className="text-nowrap">{ride.fact_start_at ? "Finish a trip" : "Start a trip"}</span>
                <Icon icon="arrow_forward" />
              </div>
            )}
          </div>
        )}
      </>
    )
  )
}

export default RideStepper
