import {Navigate, RouteObject} from "react-router-dom"
import CustomSuspense from "../components/custom-suspense"

import TransportationCities from "../pages/transportation/pages/cities/transportation-cities"
import TransferAddresses from "../pages/transportation/pages/address/transportation-addresses"
import AddressForm from "../pages/transportation/pages/address/detail/address-form"
import TransportationDrivers from "../pages/transportation/pages/drivers/transportation-drivers"
import ExpenseForm from "../pages/transportation/pages/expenses/detail/expense-form"

import RideThanks from "../pages/transportation/details/ui/ride-thanks"
import {lazy} from "react"
import {ProtectedRoute} from "./guards/protected-route"
import DriverDetailComponent from "../pages/drivers"
import DriverProvider from "../pages/drivers/detail/driver-context"
import DriverInfoComponent from "../pages/drivers/detail/driver-info"
import DriverRidesComponent from "../pages/drivers/detail/driver-rides"
import TransportationDatabase from "../pages/transportation/pages/database/transportation-database"
import CarProvider from "../pages/cars/car-context"
import CarsDetailComponent from "../pages/cars"
import TransportationFilter from "../pages/transportation/pages/transportation/transportation-filter"
import TransportationCreateGuest from "../pages/transportation/pages/transportation/transportation-create"
import TransferForm from "../pages/transportation/pages/transfer/detail/transfer-form"
import TransferStepper from "../pages/transportation/pages/transfer/detail/transfer-stepper"
import RidesFilter from "../pages/transportation/pages/rides/rides-filter"
import RideStepper from "../pages/transportation/pages/rides/detail/ride-stepper"

const TransportationListComponent = lazy(() => import("src/pages/transportation/pages/transportation/index"))
const RidesListComponent = lazy(() => import("src/pages/transportation/pages/rides/index"))
const RideExpensesListComponent = lazy(() => import("src/pages/transportation/pages/expenses/transportation-expense"))

const filterTransferRequest = "request"
const filterTransferInProcess = "inprocess"
const filterTransferCompleted = "completed"

export const transferRoutes: RouteObject[] = [
  {
    path: "transportation",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <TransportationListComponent />
          </CustomSuspense>
        }
      />
    ),
    children: [
      {
        path: "",
        element: <Navigate to="all" relative="route" replace={false} />
      },
      {path: "all", element: <TransportationFilter request={""} />},
      {
        path: "request",
        element: <TransportationFilter request={filterTransferRequest} />
      },
      {
        path: "inprocess",
        element: <TransportationFilter request={filterTransferInProcess} />
      },
      {
        path: "completed",
        element: <TransportationFilter request={filterTransferCompleted} />
      }
    ]
  },
  {
    path: "transportation/:id",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <TransportationCreateGuest />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "transportation/:id/ride",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <TransferForm />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "transportation/:id/ride/:rideId",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <TransferForm />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "transportation/:id/timeline/:rideId",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <TransferStepper />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "cities",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <TransportationCities />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "cities/:cityId",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <TransferAddresses />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "cities/:cityId/address",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <AddressForm />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "cities/:cityId/address/:addressId",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <AddressForm />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "drivers",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <TransportationDrivers />
          </CustomSuspense>
        }
      />
    )
  },

  {
    path: "drivers/:driverId",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <DriverProvider>
              <DriverDetailComponent />
            </DriverProvider>
          </CustomSuspense>
        }
      />
    ),
    children: [
      {
        path: "",
        element: <Navigate to="info" relative="route" replace={true} />
      },
      {
        path: "info",
        element: (
          <CustomSuspense>
            <DriverInfoComponent />
          </CustomSuspense>
        )
      },
      {
        path: "rides",
        element: (
          <CustomSuspense>
            <DriverRidesComponent />
          </CustomSuspense>
        )
      }
    ]
  },
  {
    path: "drivers/:driverId/expenses",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <RideExpensesListComponent />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "drivers/:driverId/expenses/expense/:expenseId",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <ExpenseForm />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "database",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <TransportationDatabase />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "/car-info/:carId",
    element: (
      <ProtectedRoute
        requiredAccessKey="transportation"
        element={
          <CustomSuspense>
            <CarProvider>
              <CarsDetailComponent />
            </CarProvider>
          </CustomSuspense>
        }
      />
    ),
    children: [
      {
        path: "",
        element: <Navigate to="info" relative="route" replace={true} />
      },
      {
        path: "info",
        element: (
          <>
            <h1>Hello</h1>
          </>
        )
      }
    ]
  },

  //rides
  {
    path: "rides",
    element: (
      <ProtectedRoute
        requiredAccessKey="rides"
        element={
          <CustomSuspense>
            <RidesListComponent />
          </CustomSuspense>
        }
      />
    ),
    children: [
      {
        path: "",
        element: <Navigate to="request" relative="route" replace={false} />
      },
      {
        path: "request",
        element: <RidesFilter request={filterTransferRequest} />
      },
      {
        path: "inprocess",
        element: <RidesFilter request={filterTransferInProcess} />
      },
      {
        path: "completed",
        element: <RidesFilter request={filterTransferCompleted} />
      }
    ]
  },
  {
    path: "rides/:rideId",
    element: (
      <ProtectedRoute
        requiredAccessKey="rides"
        element={
          <CustomSuspense>
            <RideStepper />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "rides/:rideId/finish",
    element: (
      <ProtectedRoute
        requiredAccessKey="rides"
        element={
          <CustomSuspense>
            <RideThanks />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "expenses",
    element: (
      <ProtectedRoute
        requiredAccessKey="rides"
        element={
          <CustomSuspense>
            <RideExpensesListComponent />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "expenses/expense",
    element: (
      <ProtectedRoute
        requiredAccessKey="rides"
        element={
          <CustomSuspense>
            <ExpenseForm />
          </CustomSuspense>
        }
      />
    )
  },
  {
    path: "expenses/expense/:expenseId",
    element: (
      <ProtectedRoute
        requiredAccessKey="rides"
        element={
          <CustomSuspense>
            <ExpenseForm />
          </CustomSuspense>
        }
      />
    )
  }
]
