import React, {useContext} from "react"
import {Link, Outlet, useNavigate} from "react-router-dom"

import {TabConfig, TabPanel, TabPanelConfig} from "src/components/shared/tabs/tabs"

import Icon from "src/components/shared/components/material-icon"
import HeaderContent from "src/components/header/header-content"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src/app"
import {StringMap, TOptions} from "i18next"
import {TenantType} from "src/services/tenant.service"
import Avatar from "src/utils/genereteAvatar"
import {ListModel} from "../../models/common"
import {DriverContext} from "./detail/driver-context"

export interface TabModel {
  key: string
  label: TOptions<StringMap> | string
}

const tabs: TabModel[] = [
  {key: "info", label: "clients.detail.pages.info"},
  {key: "rides", label: "transfers.main.rides"}
]

export default function DriverDetailComponent() {
  const {isMobile, tenant} = useContext(ConfigContext)
  const {driver, activeRoute} = useContext(DriverContext)
  const {t} = useTranslation()
  const navigate = useNavigate()
  const accessExceptTransfer = tenant && tenant.type_code === TenantType.TRANSFER

  const tabsConfig: TabConfig = {
    items: tabs.map((tab) => ({...tab, label: t(tab.label)})),
    onClick: (config: TabPanelConfig) => navigate(`/drivers/${driver.id}/${config.key}`),
    activeTab: activeRoute
  }

  const infoRow = (label: string, data: JSX.Element, className?: string) => (
    <div className={`flex justify-between items-center ${className}`}>
      <span>{label}</span>
      <div>{data}</div>
    </div>
  )

  const infoCard = (label: string, data: JSX.Element) => (
    <div className="flex flex-col p-4  rounded-lg border border-gray-100 shadow bg-white">
      <span className="color-gray-300">{label}</span>
      <div className="text-sm md:text-lg font-bold">{data}</div>
    </div>
  )

  const onBack = () => {
    accessExceptTransfer ? navigate(-1) : navigate("/clients")
  }

  return (
    <>
      <HeaderContent>
        <div className="flex gap-2 items-center justify-between header-content">
          <Icon onClick={onBack} className="cursor-pointer text-2xl" icon="chevron_left" />
          <div className="text-2xl font-bold flex-1">{`${driver?.first_name} ${driver?.last_name} `}</div>
          <Link to="edit" className="btn btn-black">
            <Icon className="text-lg" icon="edit" />
            {!isMobile && t("clients.detail.edit_btn")}
          </Link>
          <button className="btn btn-red btn-color-white" onClick={() => {}}>
            <Icon icon="delete" />
          </button>
        </div>
      </HeaderContent>
      <div className="-mx-4 -mt-3 md:mx-0 md:mt-0">
        <div className="p-4 flex flex-col md:flex-row gap-4 mb-4 border-gray-100 shadow bg-white md:rounded-lg">
          <div className="w-full flex flex-col gap-3">
            <div className="flex gap-4 items-center border-gray-200">
              <Avatar name={`${driver?.first_name} ${driver?.last_name} `} size={64} />
              <div>
                <div className="text-ellipsis overflow-hidden flex gap-2 font-medium text-gray-700">
                  <span className="text-gray-800 font-semibold">{driver?.first_name}</span>
                </div>
                <Link to={`tel:${driver?.phone}`} className="text-sm text-primary font-medium underline ">
                  {driver?.phone}
                </Link>
              </div>
            </div>
          </div>

          <div className="w-full grid grid-cols-2 gap-4">
            {infoCard(t("clients.detail.count"), <span>{}</span>)}
            {infoCard(t("clients.detail.orders_amount"), <span>{}</span>)}
            {infoCard(t("clients.detail.popular_group"), <span></span>)}
            {infoCard(t("clients.detail.bonus.label"), <span className="color-green">{t("common.bonus_letter")}</span>)}
          </div>
        </div>
      </div>

      <div className="p-2 rounded-lg border border-gray-100 bg-white shadow ">
        <TabPanel {...tabsConfig} style={{marginBottom: 24}} />

        <Outlet />
      </div>
    </>
  )
}
