import React from "react"
import style from "src/pages/transportation/pages/transportation/transportation.module.scss"
import toDateTime from "src/utils/date-time"
import RideLink from "../help/rideLink"
import {useTranslation} from "react-i18next"
import clsx from "clsx"

const PointStepper = ({ride, className}: {ride: any; className?: string}) => {
  const isStarted = Boolean(ride?.fact_start_at)
  const isFinished = Boolean(ride?.fact_end_at)
  const {t} = useTranslation()
  const notStartedIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="14" height="14" rx="7" fill="white" />
      <rect x="3" y="3" width="14" height="14" rx="7" stroke="#C4C4C4" strokeWidth="6" />
    </svg>
  )

  const startedIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="#52C41A"
      />
      <path
        d="M8.33329 11.3333L13.25 6.41667C13.4027 6.26389 13.5972 6.1875 13.8333 6.1875C14.0694 6.1875 14.2638 6.26389 14.4166 6.41667C14.5694 6.56944 14.6458 6.76389 14.6458 7C14.6458 7.23611 14.5694 7.43056 14.4166 7.58333L8.91663 13.0833C8.74996 13.25 8.55551 13.3333 8.33329 13.3333C8.11107 13.3333 7.91663 13.25 7.74996 13.0833L5.58329 10.9167C5.43051 10.7639 5.35413 10.5694 5.35413 10.3333C5.35413 10.0972 5.43051 9.90278 5.58329 9.75C5.73607 9.59722 5.93051 9.52083 6.16663 9.52083C6.40274 9.52083 6.59718 9.59722 6.74996 9.75L8.33329 11.3333Z"
        fill="white"
      />
    </svg>
  )

  const notFinishedIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="14" height="14" rx="7" fill="white" />
      <rect
        x="3"
        y="3"
        width="14"
        height="14"
        rx="7"
        stroke={isStarted && !isFinished ? "#A165FD" : "#C4C4C4"}
        strokeWidth="6"
      />
    </svg>
  )
  // Когда доехали до пункта B, иконка такая же зеленая с галочкой
  const finishedIcon = startedIcon

  // Определяем какие иконки использовать
  // Точка "From":
  // - Если еще не начато: notStartedIcon
  // - Если начато, но не окончено: startedIcon
  // - Если закончено: startedIcon (аналогично, так как по завершению тоже зеленая)
  const fromIcon = !isStarted ? notStartedIcon : startedIcon

  // Точка "To":
  // - Если еще не начато: notStartedIcon
  // - Если начато, но не окончено: notFinishedIcon (с фиолетовой обводкой)
  // - Если закончено: finishedIcon
  const toIcon = !isStarted ? notStartedIcon : !isFinished ? notFinishedIcon : finishedIcon

  return (
    <div className={clsx("card-wrapper flex flex-col gap-3 shadow-none md:shadow flex-1 p-4", className)}>
      <div
        className={style.line}
        style={
          {
            "--line-bg-color": isStarted ? "rgba(82, 196, 26, 0.2)" : "#C4C4C4"
          } as React.CSSProperties
        }>
        <span className={style.dot}>{fromIcon}</span>
        <div className="ml-9">
          <div className="text-lg font-medium">{t("transfers.form.from")}:</div>
          <div className="flex-col flex gap-3">
            <div className="flex gap-2 items-center text-gray-test-370">
              <span>Address:</span>
              {<RideLink name={ride?.start_address?.name} link={ride?.start_address?.link} />}
            </div>
            <div className="text-gray-test-370">
              {t("transfers.form.arrival_time")}: {toDateTime(ride?.start_at)}
            </div>
            <div className="text-gray-test-370">
              {t("transfers.form.actual_arrival_time")}: {toDateTime(ride?.fact_start_at)}
            </div>
            <div className="text-gray-test-370">
              {t("transfers.form.guest")}: {ride?.client_name}
            </div>
            <div className="text-gray-test-370">
              {t("transfers.form.number_of_guests")}: {ride?.client_count}
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <span className={style.dot}>{toIcon}</span>
        <div className="ml-9">
          <div className="text-lg font-medium">{t("transfers.form.to")}:</div>
          <div className="flex-col flex gap-3">
            <div className="flex gap-2 items-center text-gray-test-370">
              <span>{t("transfers.form.address")}:</span>
              {<RideLink name={ride?.end_address?.name} link={ride?.end_address?.link} />}
            </div>
            <div className="text-gray-test-370">
              {t("transfers.form.number_of_guests")}: {ride?.client_count}
            </div>
            <div className="text-gray-test-370">
              {t("transfers.form.actual_arrival_time")}: {toDateTime(ride?.fact_end_at)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PointStepper
