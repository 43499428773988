import {useContext, useMemo, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import Icon from "../shared/components/material-icon"
import {Link, useLocation} from "react-router-dom"
import clsx from "clsx"
import {ConfigContext} from "src/app"
import {GroupType} from "src/services/auth.service"
import {TenantType} from "src/services/tenant.service"

export function MobileMenu() {
  const ref = useRef(null)
  const location = useLocation()
  const {t} = useTranslation()
  const {isMobile, profile, tenant, mobileMenu} = useContext(ConfigContext)
  const has_perms = (key: string) =>
    profile && (profile.perms.some((perm) => perm.includes(key)) || profile.is_superuser)

  const menus = [
    {
      path: "/orders",
      icon: "list_alt",
      label: t("aside.menu.orders"),
      active: has_perms("orderrequest")
    },
    {
      path: "/deliveries",
      icon: "local_shipping",
      label: t("aside.menu.delivery"),
      active: has_perms("orderrequestdelivery")
    },
    {
      path: "/reports",
      icon: "insert_chart",
      label: t("aside.menu.metrics"),
      active: has_perms("metrics")
    },
    {
      path: "/profile",
      icon: "account_circle",
      label: t("profile.profile"),
      active: true
    }
  ]

  const currentMenus = useMemo(() => {
    if (!profile) return []

    if (tenant.type_code === TenantType.TRANSFER) {
      if (profile.group_type === GroupType.DRIVER)
        return [
          {
            path: "/rides",
            icon: "home",
            label: t("orders.transfer.all_order"),
            active: true
          },
          {
            path: "expenses",
            icon: "insert_chart",
            label: t("orders.transfer.expenses"),
            active: true
          },
          {
            path: "profile",
            icon: "account_circle",
            label: t("orders.transfer.my_account"),
            active: true
          }
        ]

      if (profile.group_type === GroupType.MANAGER)
        return [
          {
            path: "/transportation/all",
            icon: "home",
            label: t("orders.transfer.all_order"),
            active: true
          },
          {
            path: "/cities",
            icon: "location_on",
            label: t("orders.transfer.destinations"),
            active: true
          },
          {
            path: "profile",
            icon: "account_circle",
            label: t("orders.transfer.my_account"),
            active: true
          }
        ]

      if (profile.group_type === GroupType.ADMIN)
        return [
          {
            path: "/rides",
            icon: "home",
            label: t("orders.transfer.all_order"),
            active: true
          },
          {
            path: "/drivers",
            icon: "insert_chart",
            label: t("orders.transfer.expenses"),
            active: true
          },
          {
            path: "/cities",
            icon: "location_on",
            label: t("orders.transfer.destinations"),
            active: true
          },
          {
            path: "profile",
            icon: "account_circle",
            label: t("orders.transfer.my_account"),
            active: true
          }
        ]

      if (profile.is_superuser)
        return [
          {
            path: "/transportation",
            icon: "home",
            label: t("orders.transfer.all_order"),
            active: has_perms("orderrequest")
          },
          {
            path: "expenses",
            icon: "insert_chart",
            label: t("orders.transfer.expenses"),
            active: true
          },
          {
            path: "/reports",
            icon: "insert_chart",
            label: t("aside.menu.metrics"),
            active: has_perms("metrics")
          },
          {
            path: "/profile",
            icon: "account_circle",
            label: t("profile.profile"),
            active: true
          }
        ]
    }

    return menus
  }, [tenant, profile, menus])

  if (!isMobile) return <></>
  if (!mobileMenu) return <></>

  return (
    <div
      ref={ref}
      className="fixed flex items-center w-full px-2 shadow-menu bg-white max-h-[72px] bottom-0 left-0 right-0 z-[1001] gap-1">
      {currentMenus
        .filter((menu) => menu.active)
        .map((menu) => (
          <Link
            key={menu.path}
            to={menu.path}
            className={clsx("flex flex-col gap-[6px] justify-center items-center w-full pt-3 pb-2 text-center", {
              "text-primary": location.pathname.startsWith(menu.path)
            })}>
            <Icon icon={menu.icon} />
            <span className="text-[13px] text-ellipsis line-clamp-1">{menu.label}</span>
          </Link>
        ))}
    </div>
  )
}
