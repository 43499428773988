import axios from "axios"
import {ApiAbstract} from "../../abstract/api.abstract"
import {MetricsHighlight} from "src/models/manager/metrics/highlights"
import {MetricsDashboard} from "src/models/manager/metrics/dashboard"
import {ListModel} from "src/models/common"
import {OrderRequestInventoryEfficency} from "src/models/manager/metrics/efficency"
import {InventoryEarningDetailModel, InventoryEarningModel} from "src/models/manager/metrics/payback-inventory"
import {ReportUserSalary} from "src/models/manager/metrics/user_activity"
import {MetricsSellDashboard} from "src/models/manager/metrics/sales"
import {MetricsClientDashboard} from "src/models/manager/metrics/clients"
import {MetricsPaymentGraphDataModel} from "src/models/manager/metrics/payments-metrics"
import {
  UserSubleaseEarningInventory,
  UserSubleaseMetrics,
  UserSubleaseEarningHistory,
  UserSubleaseMetricsGeneral
} from "src/models/manager/metrics/sublease"
import {
  InventoryGroupEarningModel,
  InventoryGroupEarningAggregateModel
} from "src/models/manager/metrics/payback-product"
import {MetricsTransactionModel} from "src/models/manager/order/order-request-payment"
import {WorkshiftModel} from "../workshift.service"
import {OrderRequestPenaltyModel} from "src/models/manager/order/order-request-penalty"
import {InventorySetEarningModel} from "src/models/manager/metrics/payback-set"
import {
  MetricsClientBonusBalance,
  MetricsClientBonusHistory,
  MetricsClientBonusHistoryGeneral
} from "src/models/manager/metrics/client-bonus"
import {UserSalaryDetailModel, UserSalaryTypeEarningModel} from "src/models/manager/profile/statistics"
import {
  UserServiceMetrics,
  UserServiceMetricsGeneral,
  UserServiceRequestMetrics
} from "src/models/manager/metrics/service"
import {MetricsClientCountGraph} from "src/models/manager/metrics/clients-graph"
import {MetricsDiscountHistory, MetricsDiscountModel} from "src/models/manager/metrics/discount"
import {MetricsDriverUser} from "src/models/manager/metrics/driver"
import {Transfer} from "../../models/manager/transfer/transfer"

export class MetricsService extends ApiAbstract {
  constructor() {
    super("v1/crm/metrics")
  }

  public async getHighlights(params?: any): Promise<MetricsHighlight> {
    const response = await axios.get(this.getUrl("highlights"), {params})
    return response.data
  }

  public async getDashboard(params?: any): Promise<MetricsDashboard> {
    const response = await axios.get(this.getUrl("dashboard"), {params})
    return response.data
  }

  public async getSellDashboard(params?: any): Promise<MetricsSellDashboard> {
    const response = await axios.get(this.getUrl("finance"), {params})
    return response.data
  }

  public async getClientDashboard(params?: any): Promise<MetricsClientDashboard> {
    const response = await axios.get(this.getUrl("clients/general"), {params})
    return response.data
  }

  public async getClientCountGraph(params?: any): Promise<MetricsClientCountGraph[]> {
    const response = await axios.get(this.getUrl("clients/graph/count"), {
      params
    })
    return response.data
  }

  public async getClientChurnRateGraph(params?: any): Promise<MetricsClientCountGraph[]> {
    const response = await axios.get(this.getUrl("clients/graph/churn_rate"), {
      params
    })
    return response.data
  }

  public async getRentsEfficency(params?: any): Promise<ListModel<OrderRequestInventoryEfficency>> {
    const response = await axios.get(this.getUrl(`efficency`), {params})
    return response.data
  }

  public async getRentsEfficencyRating(params?: any): Promise<OrderRequestInventoryEfficency[]> {
    const response = await axios.get(this.getUrl(`efficency/ratings`), {params})
    return response.data
  }

  public async listTransactions(params?: any): Promise<ListModel<MetricsTransactionModel>> {
    const response = await axios.get(this.getUrl("transactions"), {params})
    return response.data
  }

  public async listTransactionsTypes(params?: any): Promise<
    Array<{
      type: number
      name: string
      count: number
      amount: number
      portion: number
    }>
  > {
    const response = await axios.get(this.getUrl("transactions/types"), {
      params
    })
    return response.data
  }

  public async listPenalties(params?: any): Promise<ListModel<OrderRequestPenaltyModel>> {
    const response = await axios.get(this.getUrl("penalties"), {params})
    return response.data
  }

  public async listWorkshifts(params?: any): Promise<ListModel<WorkshiftModel>> {
    const response = await axios.get(this.getUrl("workshifts"), {params})
    return response.data
  }

  public async getWorkshift(id: number): Promise<WorkshiftModel> {
    const response = await axios.get(this.getUrl(`workshifts/${id}`))
    return response.data
  }

  public async listInventoryGroupPaybackGeneral(params?: any): Promise<InventoryGroupEarningAggregateModel> {
    const response = await axios.get(this.getUrl("earning/general"), {params})
    return response.data
  }

  public async listInventorySetPayback(params?: any): Promise<ListModel<InventorySetEarningModel>> {
    const response = await axios.get(this.getUrl("earning/sets"), {params})
    return response.data
  }

  public async listInventoryGroupPayback(params?: any): Promise<ListModel<InventoryGroupEarningModel>> {
    const response = await axios.get(this.getUrl("earning/groups"), {params})
    return response.data
  }

  public async listInventoryPayback(params?: any): Promise<ListModel<InventoryEarningModel>> {
    const response = await axios.get(this.getUrl("earning/inventories"), {
      params
    })
    return response.data
  }

  public async listInventoryPaybackHistory(
    inventoryId: number,
    params?: any
  ): Promise<ListModel<InventoryEarningDetailModel>> {
    const response = await axios.get(this.getUrl(`earning/inventories/${inventoryId}`), {params})
    return response.data
  }

  public async listUserSalary(params?: any): Promise<ListModel<ReportUserSalary>> {
    const response = await axios.get(this.getUrl("users/salaries"), {params})
    return response.data
  }

  public async listDrivers(params?: any): Promise<ListModel<MetricsDriverUser>> {
    const response = await axios.get(this.getUrl("drivers"), {params})
    return response.data
  }

  public async getUserSalaryDetail(id: number, params: any): Promise<UserSalaryDetailModel> {
    const response = await axios.get(this.getUrl(`users/salaries/${id}`), {
      params
    })
    return response.data
  }

  public async listUserSalaryTypeEarningList(id: number, params: any): Promise<ListModel<UserSalaryTypeEarningModel>> {
    const response = await axios.get(this.getUrl(`users/salaries/${id}/actions`), {params})
    return response.data
  }

  public async listUserSublease(params?: any): Promise<ListModel<UserSubleaseMetrics>> {
    const response = await axios.get(this.getUrl("users/sublease"), {params})
    return response.data
  }

  public async getUserSubleaseGeneral(params?: any): Promise<UserSubleaseMetricsGeneral> {
    const response = await axios.get(this.getUrl("users/sublease/general"), {
      params
    })
    return response.data
  }

  public async listUserService(params?: any): Promise<ListModel<UserServiceMetrics>> {
    const response = await axios.get(this.getUrl("users/service"), {params})
    return response.data
  }

  public async getUserServiceGeneral(params?: any): Promise<UserServiceMetricsGeneral> {
    const response = await axios.get(this.getUrl("users/service/general"), {
      params
    })
    return response.data
  }

  public async getUserServiceRequests(userId: number, params?: any): Promise<ListModel<UserServiceRequestMetrics>> {
    const response = await axios.get(this.getUrl(`users/service/${userId}/requests`), {params})
    return response.data
  }

  public async listUserSubleaseHistory(userId: number, params?: any): Promise<ListModel<UserSubleaseEarningHistory>> {
    const response = await axios.get(this.getUrl(`users/sublease/${userId}/history`), {params})
    return response.data
  }

  public async listUserSubleaseEarningInventory(
    userId: number,
    params?: any
  ): Promise<ListModel<UserSubleaseEarningInventory>> {
    const response = await axios.get(this.getUrl(`users/sublease/${userId}/inventories`), {params})
    return response.data
  }

  public async listPaymentGraphData(params?: any): Promise<MetricsPaymentGraphDataModel[]> {
    const response = await axios.get(this.getUrl("payments/graph"), {params})
    return response.data
  }

  public async listBonusGeneral(params: any): Promise<MetricsClientBonusHistoryGeneral> {
    const response = await axios.get(this.getUrl("bonus/general"), {params})
    return response.data
  }
  public async listBonusHistory(params: any): Promise<ListModel<MetricsClientBonusHistory>> {
    const response = await axios.get(this.getUrl("bonus/history"), {params})
    return response.data
  }
  public async listBonusBalance(params: any): Promise<ListModel<MetricsClientBonusBalance>> {
    const response = await axios.get(this.getUrl("bonus/balance"), {params})
    return response.data
  }

  public async getInventorization(params?: any): Promise<Record<string, number>> {
    const response = await axios.get(this.getUrl("inventorization"), {params})
    return response.data
  }
  public async listDiscount(params?: any): Promise<ListModel<MetricsDiscountModel>> {
    const response = await axios.get(this.getUrl("discounts"), {params})
    return response.data
  }
  public async listDiscountHistory(id: number, params?: any): Promise<ListModel<MetricsDiscountHistory>> {
    const response = await axios.get(this.getUrl(`discounts/${id}`), {params})
    return response.data
  }
  public async listTransferList(params?: any): Promise<ListModel<Transfer>> {
    const response = await axios.get(this.getUrl("transfers"), {params})
    return response.data
  }
  public async listRidesList(params?: any): Promise<ListModel<Transfer>> {
    const response = await axios.get(this.getUrl("transfers/rides"), {params})
    return response.data
  }
  public async getTransferStats(params?: any) {
    const response = await axios.get(this.getUrl("transfers/general"), {params})
    return response.data
  }
  public async getRidesStats() {
    const response = await axios.get("v1/crm/metrics/transfers/rides/general")
    return response.data
  }
}

const metricsService = new MetricsService()
export default metricsService
