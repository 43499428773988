import {createContext, useEffect, useState} from "react"
import {useParams} from "react-router"
import {Drivers} from "src/models/manager/transfer/drivers"
import driverService from "src/services/driver.service"
import {useCurrentRoute} from "src/hooks/useCurrentRoute"

export interface DriverContextType {
  driver: any
  driverId: string
  activeRoute: string
  getDriver: () => void
}

export const DriverContext = createContext<DriverContextType>(undefined)

const DriverProvider = ({children}) => {
  const {driverId} = useParams<{driverId: string}>()
  const [driver, setDriver] = useState<Drivers>()
  const activeRoute = useCurrentRoute()
  function getDriver() {
    driverService.getDriver(+driverId).then(setDriver)
  }

  useEffect(() => {
    getDriver()

    return () => setDriver(undefined)
  }, [driverId])

  return (
    <DriverContext.Provider value={{driver, driverId, activeRoute, getDriver}}>
      {driver && children}
    </DriverContext.Provider>
  )
}
export default DriverProvider
