import {Link, useNavigate} from "react-router-dom"
import toDateTime from "src/utils/date-time"
import {useTranslation} from "react-i18next"
import {TransferRideModel} from "src/models/manager/transfer/transfer"
import {useCallback, useContext} from "react"
import {ConfigContext} from "src/app"
import {GroupType} from "src/services/auth.service"
import {getOrderStatus} from "../../../details/help/getOrderStatus"

const RideCard = ({ride}: {ride: TransferRideModel}) => {
  const {
    id,
    transfer,
    client_count,
    end_address,
    start_address,
    driver_user_id,
    driver_user_name,
    driver_inventory_name,
    driver_inventory_unique_id,
    client_name,
    client_phone,
    status,
    start_at
  } = ride
  const {text, bg} = getOrderStatus(status)
  const {profile} = useContext(ConfigContext)
  const {t} = useTranslation()
  const navigate = useNavigate()

  const onNavigate = useCallback(() => {
    if (!profile) return
    if (profile.group_type === GroupType.ADMIN) navigate(`/transportation/${transfer}/ride/${id}`)
    if (profile.group_type === GroupType.DRIVER) navigate(`/rides/${id}`)
  }, [profile, id, transfer])

  return (
    <div className="w-full bg-white rounded-lg flex flex-col justify-between max-w-[720px]" onClick={onNavigate}>
      <div className="p-3 flex flex-col gap-3 border-b border-gray-100">
        <div className="flex justify-between items-center">
          <div className="text-base font-bold">№{id}</div>
          <div className={`text-[13px] p-2  text-white rounded-lg`} style={{backgroundColor: bg}}>
            {text}
          </div>
        </div>
      </div>

      <div className="p-3 flex flex-col gap-3 border-b border-gray-100">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-test-370">{t("transfers.form.date")}</span>
            <span className="block font-medium text-[15px] text-gray-900">{toDateTime(start_at)}</span>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-test-370">{t("transfers.form.number_of_clients")}</span>
            <span className="block font-medium text-[15px] text-gray-900">{client_count}</span>
          </div>
        </div>
      </div>

      <div className="p-3 flex flex-col gap-3 border-b border-gray-100 last:border-none">
        <div className="flex-col flex gap-3">
          <div className="text-gray-test-370">
            <span className="font-medium">
              {t("transfers.form.from")}: {start_address?.name}
            </span>
          </div>
          <div className="text-gray-test-370">
            <span className="font-medium">
              {t("transfers.form.to")}: {end_address?.name}
            </span>
          </div>
        </div>
      </div>

      {client_name && client_phone && (
        <div className="p-3 flex flex-col gap-3 border-b border-gray-100 last:border-none">
          <div className="flex bg-gray-test-70 p-3 rounded-lg flex-col gap-2">
            <div className="grid grid-cols-2 gap-2">
              <div className="flex flex-col gap-2">
                <span className="text-sm text-gray-test-370">{t("transfers.form.guest")}</span>
                <span className="block font-medium text-[15px] text-gray-900">{client_name}</span>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm text-gray-test-370">{t("transfers.form.contact")}</span>
                <span className="block font-medium text-[15px] text-gray-900">
                  <Link to={`tel:${client_phone}`} className="text-sm text-primary font-medium underline ">
                    {client_phone}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {profile && profile.group_type === GroupType.ADMIN && driver_user_id && (
        <div className="p-3 flex flex-col gap-3">
          <div className="flex bg-gray-test-70 p-3 rounded-lg flex-col gap-2">
            <div className="grid grid-cols-2 gap-2">
              <div className="flex flex-col gap-2">
                <span className="text-sm text-gray-test-370">{t("transfers.main.driver")}</span>
                <span className="block font-medium text-[15px] text-gray-900">{driver_user_name}</span>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm text-gray-test-370">{t("transfers.main.client")}</span>
                <span className="block font-medium text-[15px] text-gray-900">
                  {driver_inventory_name} ({driver_inventory_unique_id})
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RideCard
